$(document).on 'click', '[data-modal="modal"]', (e) ->
  e.preventDefault()
  element = this
  modal_controller = $(element).data('action')
  current_request = $(element).data('url')
  $.ajax current_request,
    type: 'GET'
    success: (data, jqxhr, textStatus) ->
      $('.form-body').html data
      $('.form-modal').modal()
      $('.modal-title').html("Edit " + modal_controller)
      $('.datepicker').datepicker({
        format: 'dd/mm/yyyy',
        todayHighlight: true,
        endDate: '0d',
        autoclose: true
      });
      $.each($('.select2-single'), (i) ->
        placeholder = titleize $(this).attr("id").split("_")[0]
        $('.single-select').eq(i).select2(
          allowClear: true
          placeholder: "Select " + placeholder
        )
        $('.multi-select').select2(
          placeholder: "Select " + placeholder
        )
      )
    error:(jqxhr, textStatus, errorThrown) ->
      alert 'Whoops! There was an error processing your request. Please contact support!'


titleize = (placeholder) ->
  placeholder = placeholder.toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

$(document).on 'submit', 'form.remote_event_attendee_form', ->
  valuesToSubmit = $(this).serialize()
  url = $(this).attr('action')
  element = this
  container = '[data-event-attendee-id="' + $(element).data('event-id') + '"]'
  $.ajax url,
    type: 'PATCH'
    data: valuesToSubmit
    dataType: 'JSON'
    success: (data, jqxhr, textStatus) ->
      if data.success == true
        $(container).html data.html
        $('.form-modal').modal('toggle')
        $.jGrowl('Attendee details updated successfully.', { life: 3000})
      else
        $('.form-body').html data.html
    error:(jqxhr, textStatus, errorThrown) ->
      alert 'Whoops! There was an error processing your request. Please contact support!'