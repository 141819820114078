$(document).on "turbolinks:load", () ->

  if window.location.href.indexOf("filters") == -1 && $('[data-target="#exportmodal"]').length != 0
    $('[data-behaviour="filter"]').trigger('change')

  $('.select2').select2(
      allowClear: true
  )

  $(".dynamic-multi-select").select2({
    tags: true,
    tokenSeparators: [',', ' ']
  })
  
  $.each($('.select2-single'), (i) ->
    placeholder = titleize $(this).attr("id").split("_")[0]
    $('.single-select').eq(i).select2(
      allowClear: true
      placeholder: "Select " + placeholder
    )
    $('.multi-select').select2(
      placeholder: "Select " + placeholder
    )
  )
  $(".trix-button--icon-heading-1, .trix-button--icon-quote, .trix-button--icon-code, .trix-button-group--file-tools, .trix-button--icon-strike").remove()
  

  $('.datetimepicker-input').datetimepicker({
    format: 'YYYY-MM-DD hh:mm A'
    icons: {
        time: "fa fa-clock",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down"
    }    
  })
  $('.filter-rangepicker').daterangepicker({
    autoUpdateInput: false,
    format: 'YYYY-MM-DD'
  })
  $.each($(".datetimepicker-input"), () -> 
    $(this).val($(this).attr("data-value"))
  )

  $('.filter-rangepicker').on 'apply.daterangepicker', (ev, picker) ->
    $(this).val picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY')
    $('.filter-rangepicker').change()
    return
  $('.filter-rangepicker').on 'cancel.daterangepicker', (ev, picker) ->
    $(this).val('').change() 

  $('.future-datepicker-input').datepicker({
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    autoClose: true,
    startDate: '+0d'
  })   

get_today_date = () ->
  today = new Date
  dd = today.getDate()
  mm = today.getMonth() + 1
  yyyy = today.getFullYear()
  if dd < 10
    dd = '0' + dd
  if mm < 10
    mm = '0' + mm
  today = dd + '/' + mm + '/' + yyyy
  return today

titleize = (placeholder) ->
  placeholder = placeholder.toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

$(document).on "change", "#company_sponsorship_sponsorship_package_id", () -> 
  sponsorship_package_id = $("#company_sponsorship_sponsorship_package_id option:selected").val()
  $.ajax '/admin/sponsorship_packages/fetch_total_pass',
    type:"GET",
    dataType:"json",
    data: {sponsorship_package_id: sponsorship_package_id}
    success: (data, jqxhr, textStatus) -> 
      $("#company_sponsorship_total_passes").val(data)

$(document).on 'change', '.upload-file-image', () ->
  file_path_array = $(this).val().split('\\')
  file_name = file_path_array[file_path_array.length - 1]
  data_upload = $(this).data('upload-image-class')
  if $(this).val() != ""
    $('[data-upload="' + data_upload + '"]').text(file_name.substring(0,50) + '...')
  else
    $('[data-upload="' + data_upload + '"]').text('Upload Image')

$(document).on "focusout", "#event_scheduled_from", () -> 
  input_date = $("#event_scheduled_from").val()
  $.ajax '/admin/events/fetch_quarter',
    type:"GET",
    dataType:"json",
    data: {input_date: input_date}
    success: (data, jqxhr, textStatus) ->
      $("#event_quarter_id option:eq(#{data})").prop('selected', true)
      $("#event_quarter_id").trigger('change')
      console.log(data)
    error: (data, jqxhr, textStatus) -> 
      console.log(textStatus)


$(document).on 'click', '.delete_profile_image', () ->
  parent = $(this).parent().parent()
  confirm_delete = confirm("Are you sure you want to delete this Picture!");
  if confirm_delete == true
    event_id = $(this).data('event-id')
    $.ajax '/admin/events/'+event_id+'/delete_profile_picture',
      type:'GET',
      dataType:"json",
      data: {
        image_type: $(this).data('image-type')
      }      
      success:(data) ->
        parent.find('.uploaded-file-name').removeClass('uploaded-file-name')
        parent.find('.change-text').text('Upload image')
        parent.find('.change-text').removeClass('upload-text-color')
        parent.find('.file-delete').remove()

$(document).on 'click', '.delete_event_image', () ->
  parent = $(this).parent()
  confirm_delete = confirm("Are you sure you want to delete this Picture!");
  if confirm_delete == true
    event_id = $(this).data('event-id')
    $.ajax '/admin/events/'+event_id+'/delete_event_picture',
      type:'GET',
      dataType:"json",
      data: {
        image_type: $(this).data('image-type')
      }      
      success:(data) ->
        parent.find('.uploaded-file-name').removeClass('uploaded-file-name')
        parent.find('.change-text').text('Upload image')
        parent.find('.change-text').removeClass('upload-text-color')
        parent.find('.file-delete').remove()

$(document).on "turbolinks:before-cache", () ->
  $('.select2-single').select2('destroy')
  $('.select2-multiple').select2('destroy')