// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

import jQuery from 'jquery';
window.$ = jQuery
window.jQuery = jQuery


require("spotlight_search")
require("moment")
require("daterangepicker")
require("jgrowl")
require("tempusdominus-bootstrap-4")


import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import 'select2';
import '../application/coffee_scripts/scaffolds.coffee';
import '../application/coffee_scripts/event_attendee.coffee'
import '@fortawesome/fontawesome-free/js/all.js';

require("trix")
require("@rails/actiontext")